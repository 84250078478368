
/*TODO: to component   */
.v2-button {
    @apply font-medium text-sm flex items-center justify-center gap-2 cursor-pointer text-neutral-near-white select-none rounded-[20px] border border-primary-blue bg-primary-blue/10 py-1 px-5 transition-colors duration-200 ease-in-out
}

.Dropdown-control {
    @apply !bg-primary-blue/10 !text-neutral-near-white !cursor-pointer !border-none !rounded-2xl
}

.Dropdown-menu {
    background-color: inherit !important;
    @apply !border-none !rounded-2xl

}

.Dropdown-option {
    @apply !text-neutral-near-black !cursor-pointer !bg-neutral-near-white
}

.Dropdown-option.is-selected {
    @apply !cursor-pointer !bg-neutral-400 !text-neutral-near-white
}


.v2-button.yellow {
    background: #E8BA4026;
    border: 1px solid #E8BA40

}
.v2-button.purple {
    background: #9459F526;
    border: 1px solid #9459F5
}

.v2-button.purple:hover {
    background: #7b26e34e;
}

.v2-button.clear {
    background: #24335726;
    border: 1px solid rgba(0, 0, 0, 0);

}
.v2-button.purple:hover {
    background: #7b26e34e;
}

.v2-button.yellow:hover {
    background: #e0e3264e;
}

.v2-button:hover {
    background: #26a1e34e;
}

.v2-button.alt {
    background: #24335726;
    border: 1px solid #9459F5
}

.v2-button.clear {
    background: #24335726;
    border: 1px solid rgba(0, 0, 0, 0);
}

.v2-button.alt:hover {
    background: #7b26e34e;
}
.v2-button.yellow:hover {
    background: #e0e3264e;
}

.v2-button:disabled {
    color: rgb(107, 107, 107);
    cursor:default;
    background: #24335726;
}
